<template>
<div>
    <a-card title="库存盘点详情" v-if="stdState.info">
        <template #extra>
            <a-button @click="$router.go(-1)">返回</a-button>
        </template>

        <a-space>
            <div class="std-item">
                <span class="f12">盘点单号：</span>
                <span>{{stdState.info.od }}</span>
            </div>
            <div class="std-item">
                <span class="f12">仓库：</span>
                <span>{{stdState.info.depot?.name }}</span>
            </div>
        </a-space>
        <br>
        <a-space class="mt12" style="margin-bottom: 24px">
            <div class="std-item">
                <span class="f12">建单时间：</span>
                <span>{{stdState.info.created_at }}</span>
            </div>
            <div class="std-item">
                <span class="f12">备注信息：</span>
                <span>{{stdState.info.remark }}</span>
            </div>
        </a-space>

        <a-table :pagination="false" row-key="class_id" :data-source="stdState.info.inventory_data"  :columns="[
            {title:'盘点类型',dataIndex:'type',slots:{customRender:'type'}},
            {title:'产品信息',dataIndex:'product',slots:{customRender:'product'}},
            {title:'产品规格',dataIndex:'sku_id',slots:{customRender:'sku_id'}},
            {title:'盘点库存',dataIndex:'inventory_stock'},
            {title:'盘点盈亏',dataIndex:'inventory_loss'},
            {title:'备注',dataIndex:'remark'},
        ]">
            <template #type="{record}">
                <a-tag :color="record.type ===1 ?'#00CC66':'#0099FF'">
                    {{record.type ===1 ?'产品':'农资'}}
                </a-tag>
            </template>
            <template #product="{record}">
                <span v-if="record.classInfo">{{record.classInfo.name}}</span>
            </template>
            <template #sku_id="{record}">
                <span v-if="record.classInfo">{{record.classInfo.model}}</span>
            </template>
        </a-table>

        <a-space class="mt12">
            <a-button @click="exportStockTalk(null)" v-has="{action:'wxapp_storage_stock_export',plat:0}">导出盘点库存</a-button>
            <a-button @click="exportStockTalk('all')" v-has="{action:'wxapp_storage_stock_export',plat:0}">生成盘点数据</a-button>
        </a-space>

    </a-card>
</div>
</template>

<script>
import { useStockTalkDetail } from '@/models/addons/storage'
import {useRoute} from "vue-router";
export default {
    setup(){
        let opt = useRoute().query
        let {stdState,exportStockTalk } = useStockTalkDetail(opt.id)

        return { stdState,exportStockTalk }
    }
}
</script>

<style scoped lang="scss">
.std-item{
    width: 400px;
}
</style>
